import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom';
import Dashboard from 'Routes/Dashboard';
import Archive from './Archive';
import Expired from './Expired';
import Link from './Link';

import './style.css';

const Routes = () => {
    return (
        <div className='Routes container'>
            <Switch>
                <Route exact path="/home/dashboard">
                    <Dashboard />
                </Route>

                <Route exact path="/home/archive">
                    <Archive />
                </Route>

                <Route exact path="/home/expired">
                    <Expired />
                </Route>

                <Route exact path="/home/link/:id">
                    <Link />
                </Route>

                <Redirect from="*" to="/login" />
            </Switch>
        </div>
    )
}

export default Routes
