import React , {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {Spring} from 'react-spring/renderprops'


import './style.css'

interface Props {
  btnText: string;
  isOpen?: boolean;
  onChange?(state: boolean): void;
  render?(): React.ReactNode
}

const DropDown = ({ btnText, isOpen, onChange, render = ()=>undefined }: Props) => {

    const [state_isOpen, setState_isOpen] = useState(isOpen || false);

    const OnDropDownClick = () =>{
        if(onChange) onChange(!state_isOpen);
        if(isOpen === undefined)setState_isOpen(!state_isOpen);
    }


    useEffect(()=> {
        if(isOpen !== undefined) setState_isOpen(isOpen);
    }, [isOpen])



  return (
    <div className="DropDown">
      <div className="btn btn-dropdown" onClick={OnDropDownClick}>
          <div className="DropDown_container">
            <div className="DropDown__btnText">
                {btnText}
            </div>
                <FontAwesomeIcon icon={state_isOpen ? faArrowUp: faArrowDown}/>
            </div>
          </div>
        <Spring 
        config={{mass: 1 ,tension: 500, friction: 35}}
         from={{ height: state_isOpen ? 0: 'auto' }}
         to={{ height: state_isOpen ? 'auto' : 0  }}
         >
          {(styles)=>
          <div style={{...styles, overflow:'hidden'}}>
             <div className="DropDown__options">
             {render()}
             </div>
            </div>
            }
        </Spring>
    </div>
  );
};

export default DropDown;
