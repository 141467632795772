import React, { useContext, useEffect } from "react";
import { AppContext, Location } from "context";
import { ShortLink } from "types/ShortLink";
import "./style.css";
import Http from "services/http";
import LinksList from "Components/LinksList";
import LinksListItem from "../LinksListItem";
import { useHistory } from "react-router-dom";




const ActiveLinksList = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const onDelete = async(link: ShortLink) => {
    try {
      const didDelete = await Http.DeleteShortLink(link.linkId);
      if(didDelete) context.dispatch_activeLinks({ type: "REMOVE", payload: link.linkId });
    } catch (error) {
      console.error(error);
      if(error.code === 401) history.replace('/login')
    }
    
  };



  const onArchive = async(link: ShortLink) => {
    try {
      const didArchive = await Http.UpdateLinkArchive(link.linkId);
      if(didArchive) context.MoveTo(link, Location.active, Location.archive);
    } catch (error) {
      console.error(error)
      if(error.code === 401) history.replace('/login')
    }
    
  };



  
  const onExpire = async(link: ShortLink) => {
    try {
      const didArchive = await Http.UpdateLinkisNotActive(link.linkId);
      if(didArchive) context.MoveTo(link, Location.active, Location.expired);
    } catch (error) {
      console.error(error)
      if(error.code === 401) history.replace('/login')
    }
    
  };




  useEffect(()=> {
    const getActiveList = async() =>{
      try {
        const links = await Http.GetActiveLinks();
        context.dispatch_activeLinks({type:'PUT', payload: links.reverse()})
      } catch (error) {
        if(error.code === 401) history.replace('/login')
      }
    }

    getActiveList();
    // eslint-disable-next-line
  },[])

  return (
    <div className="ActiveLinksList">
      <LinksList links={context.activeLinks} render={(link) =>   <LinksListItem link={link} onArchive={onArchive} onDelete={onDelete} onExpire={onExpire}/>}/>
    </div>
  );
};




export default ActiveLinksList;
