import React, { useState } from "react";
import UrlShorter from "Components/UrlShorter";
import ActiveLinksList from "Components/LinksList/ActiveLinksList";
import { Activity, ActivityList } from "types/Activity";
import { Datum } from "types/Statistic";
import { ShortLink } from "types/ShortLink";

import "./style.css";
import Http from "services/http";
import RealTimeFeed from "Components/RealTimeFeed";
import Map from "Components/Map";
import ReactTooltip from "react-tooltip";
import Time from "Components/Time";

const Dashboard = () => {
  const [recentActivies, setState_recentActivies] = useState<Datum[]>([]);

  const [content, setContent] = useState("");

  const onActivitiesChange = (newActivity: ActivityList) => {
    const parsed = newActivity.map<Datum>((item) => ({
      name: item.CountryLong,
      value: item.osInfo_name,
    }));
    setState_recentActivies(parsed);
  };

  return (
    <div className="Dashboard">
      <div className="Dashboard__left">
        <UrlShorter />

        <ActiveLinksList />
      </div>

      <div className="Dashboard__right">
        <div className="map">
          <Map data={recentActivies} setTooltipContent={setContent} />
          <ReactTooltip>{content}</ReactTooltip>
        </div>

        <RealTimeFeed
          title="Recent Activities"
          maximumItems={5}
          fetchFeed={Http.GetClickRecentActivities}
          onChange={onActivitiesChange}
          renderItem={(item: Activity) => (
            <div className='Recent_Container'>
              <div>
              <b>{item.osInfo_name}</b> user from <b>{item.CountryLong}</b>{" "}
              visited <b className="text-primary">{item.title}</b>
                </div>
              <Time date={item.dateInsert}/>
            </div>
          )}
        />

        <RealTimeFeed
          title="Top URLS"
          maximumItems={5}
          fetchFeed={Http.GetClickTopURLs}
          renderItem={(item: ShortLink) => (
            <div className='TopUrls_Container'>
            <div className="TopUrls_item">
              <img src={item.icon} alt="favicon" />
              <a
                className="TopUrls_site"
                href={item.shortlink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
              -<span className="TopUrls_click">{item.clicks} Click</span>
            </div>
            <Time date={item.dateInsert}/>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Dashboard;
