import React from 'react'
import ArchiveLinksList from 'Components/LinksList/ArchiveLinksList'



const Archive = () => {
    return (
        <div className='Archive'>
            <ArchiveLinksList/>
        </div>
    )
}

export default Archive
