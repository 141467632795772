import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Card from "Container/Card";

import "./style.css";
import { Spring } from "react-spring/renderprops";

interface Props {
  title(): React.ReactNode;
  content(): React.ReactNode;
  isOpen: boolean;
  onClose?(state: boolean): void;
}

const Popup = ({ title, content, isOpen, onClose }: Props) => {
  const onCloseClick = () => {
    if (onClose) onClose(false);
  };

  return (
    <Spring
      config={{ mass: 1, tension: 500, friction: 35 }}
      from={{ height: isOpen ? 0 : "auto" }}
      to={{ height: isOpen ? "auto" : 0 }}
    >
      {styles => (
        <div style={styles} className="Popup__dropshadow">
          <div className="Popup__content">
            <Card
              renderHeader={() => (
                <div className="Popup__header">
                  <div className="Popup__close">
                    <FontAwesomeIcon icon={faTimes} onClick={onCloseClick} />
                  </div>
                  {title()}
                </div>
              )}
              renderDescription={() => (
                <div className="Popup__description">{content()}</div>
              )}
            />
          </div>
        </div>
      )}
    </Spring>
  );
};

export default Popup;
