import React, { useState, useEffect } from "react";
import { randomColor } from "utils";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Datum } from "types/Statistic";

import "./style.css";

interface Props {
  title: string;
  data: Datum[];
  outerRadius: number;
  fill: string;
}

const PieContainer = ({ title, data, outerRadius, fill }: Props) => {
  const [colors, setState_colors] = useState<Array<string>>([]);

  useEffect(() => {
    const _colors = data.map(_ => randomColor());
    setState_colors(_colors);
  }, [data, data.length]);

  const size = outerRadius * 2;
  return (
    <div className="PieContainer">
      <div className="Pie">
        <div className="Pie__title">{title}</div>
        <PieChart width={size} height={size}>
          <Pie
            data={data}
            cx={size / 2 - 5}
            cy={size / 2 - 5}
            outerRadius={outerRadius}
            fill={fill}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell fill={colors[index]} key={index}/>
            ))}
          </Pie>
          <Tooltip />
        </PieChart>

        <div className="Pie__Map">
          {data.map((item, i) => (
            <div className="Pie__Map__item" key={i}>
              <div
                className="color"
                style={{ backgroundColor: `${colors[i]}` }}
              ></div>
              <div className="text">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PieContainer;
