
import {ShortLink, ShortLinkList} from 'types/ShortLink';

export type LinkState = ShortLinkList;


export type Action =
  | { type: "ADD"; payload: ShortLink }
  | { type: "PUT"; payload: ShortLinkList }
  | { type: "REMOVE"; payload: string }
  | { type: "UPDATE"; payload: ShortLink };

export function LinksReducer(state: LinkState, action: Action): LinkState {
  switch (action.type) {
   case "ADD": 
        return [...state , action.payload];
    case "PUT":
        return action.payload;
    case "REMOVE":
      return state.filter(link => link.linkId !== action.payload)
    case "UPDATE":
        return state.map(link => link.linkId === action.payload.linkId ? action.payload : link)
    default:
      return state;
  }
}