import React, {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
    types: string,
    children: React.ReactNode,
    onClick?(): Promise<any>
}

const AsyncButton = ({types, children, onClick}: Props) => {

    const [state_isLoading, setState_isLoading] = useState(false);
    const isMountedRef = useRef<boolean | null>(null);

    const buttonClick = async() => {
       
        if(!onClick) return;
        setState_isLoading(true);
        try {
            await onClick();
            
        } catch (error) {
            
        }
        if(!isMountedRef.current) return;
        setState_isLoading(false);
    }

   useEffect(() => {
    isMountedRef.current = true;
       return () => {
           isMountedRef.current = false
       }
   }, [])

    return (
        <div className={`btn ${types}`} onClick={buttonClick}>
            {state_isLoading ? <FontAwesomeIcon icon={faSpinner} spin/> : children}
        </div>
    )
}

export default AsyncButton
