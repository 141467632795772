import React from 'react'
import ExpiredLinksList from 'Components/LinksList/ExpiredLinksList'



const Expired = () => {
    return (
        <div className='Expired'>
            <ExpiredLinksList/>
        </div>
    )
}

export default Expired
