import React from "react";
import SideMenu from "Components/SideMenu";
import Header from "Components/Header";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Routes from "Routes";

import "./style.css";
import Login from "Routes/Login";

function App() {
  return (
    <BrowserRouter basename="/app">
      <Switch>
        <Route path="/home">
          <>
            <Header></Header>
            <div className="App">
              <SideMenu></SideMenu>

              <Routes />
            </div>
          </>
        </Route>

        <Route exact path="/login">
          <Login />
        </Route>

        <Redirect from="*" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
