import React, {useReducer, useState, useRef, useEffect} from 'react'
import {LinksReducer, LinkState, Action } from 'context/reducers/Links';
import {ShortLink} from 'types/ShortLink';

interface Props{
    children: React.ReactNode
}



interface Context{
    activeLinks: LinkState,
    dispatch_activeLinks: React.Dispatch<Action>,

    archiveLinks: LinkState,
    dispatch_archiveLinks: React.Dispatch<Action>,

    expiredLinks: LinkState,
    dispatch_expiredLinks: React.Dispatch<Action>,

    MoveTo(link: ShortLink, from: Location, to:Location): void,
    getLocation(linkId: string): Location | undefined,


    username: string,
    set_username: React.Dispatch<React.SetStateAction<string>>
}


const NullObject: Context = {
    activeLinks: [],
    archiveLinks: [],
    expiredLinks: [],

    dispatch_activeLinks: ()=>null,
    dispatch_archiveLinks: ()=>null,
    dispatch_expiredLinks : ()=> null,
    MoveTo:({} , {} , {}) => null,
    getLocation: () => Location.active,

    username: '',
    set_username: () => null

}

export const AppContext = React.createContext<Context>(NullObject);

export enum Location {
    active,
    archive,
    expired
}

const AppProvider = ({children}: Props) => {

    const [activeLinks, dispatch_activeLinks] = useReducer(LinksReducer, [])
    const [archiveLinks, dispatch_archiveLinks] = useReducer(LinksReducer, [])
    const [expiredLinks, dispatch_expiredLinks] = useReducer(LinksReducer, [])
    const [username , set_username] = useState('');

    const MoveTo = (link: ShortLink, from: Location, to:Location) => {
        switch(from){
            case Location.active:
                dispatch_activeLinks({type: "REMOVE", payload: link.linkId});
                break;
            case Location.expired:
                dispatch_expiredLinks({type: "REMOVE", payload: link.linkId});
                break;
            case Location.archive:
                dispatch_archiveLinks({type: "REMOVE", payload: link.linkId});
                break;
            default: break;
        }

        switch(to){
            case Location.active:
                dispatch_activeLinks({type: "ADD", payload: {...link, isActive: true, isArchive: false}});
                break;
            case Location.expired:
                dispatch_expiredLinks({type: "ADD", payload: {...link, isActive: false, isArchive: false}});
                break;
            case Location.archive:
                dispatch_archiveLinks({type: "ADD", payload: {...link, isActive: true, isArchive: true}});
                break;
            default: break;
        }
    }

    const getLocation = (linkId: string) => {
        if(activeLinks.find((link) => link.linkId === linkId)) return Location.active;
        if(archiveLinks.find((link) => link.linkId === linkId)) return Location.archive;
        if(expiredLinks.find((link) => link.linkId === linkId)) return Location.expired;
    }




    const state = {
        activeLinks,
        dispatch_activeLinks,

        archiveLinks,
        dispatch_archiveLinks,

        expiredLinks,
        dispatch_expiredLinks,

        MoveTo,
        getLocation,


        set_username,
        username
    }


 


    return (
        <AppContext.Provider value={state}>


            {children}
        </AppContext.Provider>
    )
}

export default AppProvider
