import React, { useContext, useEffect } from "react";
import { AppContext, Location } from "context";
import { ShortLink } from "types/ShortLink";
import Http from "services/http";
import LinksList from "Components/LinksList";

import "./style.css";
import LinksListItem from "../LinksListItem";



const ExpiredLinksList = () => {
  const context = useContext(AppContext);

  const onDelete = async(link: ShortLink) => {
    try {
      const didDelete = await Http.DeleteShortLink(link.linkId);
      if(didDelete) context.dispatch_expiredLinks({ type: "REMOVE", payload: link.linkId });
    } catch (error) {
      console.error(error);
    }
    
  };



  const onArchive = async(link: ShortLink) => {
    try {
      const didArchive = await Http.UpdateLinkArchive(link.linkId);
      if(didArchive) context.MoveTo(link, Location.expired, Location.archive);
    } catch (error) {
      console.error(error)
    }
    
  };


  useEffect(()=> {
    const getExpiredLinks = async() =>{
      try {
        const links = await Http.GetLinksExpired();
        context.dispatch_expiredLinks({type:'PUT', payload: links.reverse()})
      } catch (error) {
        
      }
    }

    getExpiredLinks();
    // eslint-disable-next-line
  },[])

  return (
    <div className="ExpiredLinksList">
      <LinksList links={context.expiredLinks} render={(link) => 
            <LinksListItem link={link} onArchive={onArchive} onDelete={onDelete}/>
      }/>
    </div>
  );
};




export default ExpiredLinksList;
