import {ShortLinkList, ShortLink} from 'types/ShortLink';
import {Statistic} from 'types/Statistic';
import {ActivityList} from 'types/Activity';
import {HttpStatusCodes} from './types';


const endpoint = process.env.REACT_APP_ENDPOINT_URL;
const headers = new Headers();
headers.append('Authorization' , '');
headers.append('Access-Control-Allow-Origin','*');
headers.append('Content-Type','application/json');


const mocking = false;

class Http{

    static async GetLinksArchive(): Promise<ShortLinkList>{

        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetLinksArchive.json')).default;
            return mockData
        }
        
        
        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/GetLinksArchive`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    
    
    static async GetActiveLinks(): Promise<ShortLinkList>{

        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetActiveLinks.json')).default;
            return mockData
        }
        
                if(!headers.get("Authorization")){
                    const token = sessionStorage.getItem("token");
                   const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
                    if(!token) throw error_unauthorized;
                    headers.set('Authorization', token);
                }

        const res = await fetch(`/dashbord/GetLinksNew`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    

    static async GetLinksExpired(): Promise<ShortLinkList>{

        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetLinksExpired.json')).default;
            return mockData
        }
        

        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/GetLinksExpired`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }


    static async GetClickTopURLs(): Promise<ShortLinkList>{


        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetClickTopURLs.json')).default;
            return mockData
        }
        
                if(!headers.get("Authorization")){
                    const token = sessionStorage.getItem("token");
                   const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
                    if(!token) throw error_unauthorized;
                    headers.set('Authorization', token);
                }

        const res = await fetch(`/dashbord/GetClickTopURLs`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }


    
    static async GetClickRecentActivities(): Promise<ActivityList>{


        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetClickRecentActivities.json')).default;
            return mockData;
        }
        
        
                if(!headers.get("Authorization")){
                    const token = sessionStorage.getItem("token");
                   const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
                    if(!token) throw error_unauthorized;
                    headers.set('Authorization', token);
                }

        const res = await fetch(`/dashbord/GetClickRecentActivities`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        if(data === null) return [];
        return data;
    }




    static async GetLinkClickDetails(linkId: string): Promise<Statistic>{

        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_GetLinksClickDetails.json')).default;
            return mockData as Statistic;
        }
        
                if(!headers.get("Authorization")){
                    const token = sessionStorage.getItem("token");
                   const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
                    if(!token) throw error_unauthorized;
                    headers.set('Authorization', token);
                }

        const res = await fetch(`/dashbord/GetLinkClickDetails?linkId=${linkId}`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    
    

    static async CreateShortLink(link: string, title: string, expireDate?: string): Promise<ShortLink>{


        if(mocking){
            // eslint-disable-next-line
            const mockData = (await import('services/http/mocks/mock_CreateShortLink.json')).default;
            return mockData;
        }
        
        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/SetShortLink`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                url: link,
                title: title,
                expiredDate: expireDate || null
            })
        });

        const error = {code: res.status, message: res.statusText}
         if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    


    static async DeleteShortLink(linkId: string): Promise<boolean>{


        if(mocking) return true;

        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/DeleteLink?linkId=${linkId}`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok) throw error
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    
    
    
    static async UpdateLinkArchive(linkId: string): Promise<boolean>{

        if (mocking) return true;

        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/UpdateLinkArchive?linkId=${linkId}`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    

     
    static async UpdateLinkisNotActive(linkId: string): Promise<boolean>{

        if(mocking) return true;

        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/UpdateLinkisNotActive?linkId=${linkId}`, {
            method: 'GET',
            headers: headers
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    

    static async UpdateLink(linkId: string, title: string ,url: string,  expireDate?: string): Promise<boolean>{

        if(mocking) return true;

        if(!headers.get("Authorization")){
            const token = sessionStorage.getItem("token");
           const error_unauthorized = {code: HttpStatusCodes.Unauthorized, message: 'Unauthrized'};
            if(!token) throw error_unauthorized;
            headers.set('Authorization', token);
        }

        const res = await fetch(`/dashbord/UpdateShortLink`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                linkId: linkId,
                url: url,
                title: title,
                expiredDate: expireDate || null
              })
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok){ 
            if( res.status === 401) sessionStorage.removeItem('token');
            throw error;
        }
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        return data;
    }
    


    static async Login(user: string, password: string): Promise<string>{

        
        if (mocking) return "MOCKING:TOKEN";

        const res = await fetch(`/account/Login?username=${user}&password=${password}`, {
            method: 'GET'
        });

        const error = {code: res.status, message: res.statusText}
        if(!res.ok) throw error
        

        //check status code to match custom throw
        //check if server returned anyting other than ok

        const data = await res.json();
        await sessionStorage.setItem('token', data.authorization);
        headers.set('Authorization', data.authorization);
        return data.authorization;
    }
}

export default Http;