import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Card from "Container/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { CopyToclipboard } from "utils";
import { Statistic } from "types/Statistic";
import Http from "services/http";

import "./style.css";
import PieContainer from "Container/PieContainer";
import BarChartContainer from "Container/BarChartContainer";
import MapContainer from 'Container/MapContainer';



const Link = () => {
  const { id } = useParams<{ id: string }>();

  const [data, setState_data] = useState<Statistic | null>(null);
  const history = useHistory();


  useEffect(() => {
    const fetchStats = async () => {
      try {
        const stats = await Http.GetLinkClickDetails(id);
        setState_data(stats);
        console.log(stats);
      } catch (error) {
        if(error.code === 401) history.replace('/login')
      }
    };
    fetchStats();
    // eslint-disable-next-line
  }, [id]);


 

  const renderFeature = (key: string) => {
    const features = data?.linkClickDetails
      .filter(item => item.type === key)
      .map((feature, index) => {
        switch (key) {
          case "PIE":
            return (
              <PieContainer
                title={feature.featureName}
                data={feature.data}
                fill={"black"}
                outerRadius={80}
                key={index}
              />
            );

          case "date":
            return (
              <BarChartContainer
                fill="var(--action-color-sd)"
                height={200}
                width={1050}
                title={feature.featureName}
                data={feature.data}
                key={index}
              />
            );
          case 'Country':
              return (
              <MapContainer data={feature.data}  title={feature.featureName}/>
              )
          default:
            return null;
        }
      });

    if (features?.length === 0) return null;
    return features;
  };

  const Copy = async () => {
    const text = data?.shortlink || "";

    try {
      await CopyToclipboard(text);
    } catch (error) {}
  };

  return (
    <div className="Link">
      <Card
        renderHeader={() => (
          <div className="Link__info">
            <div className="Link__title">
              {" "}
              <img src={data?.icon} alt="favicon" />
              <div className="text">{data?.title}</div>{" "}
            </div>
            <div className="Link__clicks">
              <div className="Link__clicks_number">{data?.click}</div>
              <div className="Link__clicks_text">Clicks</div>
            </div>

            <div className="Link__clicks">
              <div className="Link__clicks_number">{data?.uniqueClicks}</div>
              <div className="Link__clicks_text">Unique Clicks</div>
            </div>

            <div className="Link__detail">
              <FontAwesomeIcon icon={faLink} />

              <div className="url">{data?.shortlink}</div>
              <span className="btn btn-sm btn-tag btn-tag-left" onClick={Copy}>
                Copy
              </span>
            </div>
          </div>
        )}
      />

      <div className="Link_Stats">
        <Card renderHeader={() => renderFeature("PIE")} />
        <Card renderHeader={() => renderFeature("date")} />

        <Card renderHeader={() => renderFeature("Country")} />
      </div>
    </div>
  );
};

export default Link;
