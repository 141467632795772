import React, { useState } from "react";
import Map from "Components/Map";
import { Datum } from "types/Statistic";

import ReactTooltip from "react-tooltip";
import "./style.css";

interface Props {
  title: string;
  data: Datum[];
}

const MapContainer = ({ title, data }: Props) => {
  
  const [content, setContent] = useState("");

  return (
    <div className="MapContainer">
        <div className="MapContainer__title">{title}</div>
        <div className='MapContainer__content'>
        <Map setTooltipContent={setContent} data={data}/>
          <div className='MapContainer__topCountries'>
            {data.sort((a,b) => typeof b.value === 'number' && typeof a.value === 'number' ? b.value - a.value : 0).slice(0,10).map(country => 
                <div>{country.name} - {country.value}</div>
              )}
          </div>
        </div>
     
        <ReactTooltip>{content}</ReactTooltip>
    </div>
  );
};

export default MapContainer;
