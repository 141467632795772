import React from 'react'
import moment from "moment";

import './style.css';


interface Props {
    date: string,
    format?: boolean
}

const Time = ({date, format=true}: Props) => {

    const hoursDiff = (date1: Date, date2 = new Date()) => {
        return Math.abs((date2.getTime() - date1.getTime()) / (1000* 60 * 60));
        
    }


    return (
        <div className='Time'>
          {( format && hoursDiff(moment(date).toDate()) <= 24) ?   moment(date).fromNow() : moment(date).format('DD/MM/YYYY')}
        </div>
    )
}

export default Time
