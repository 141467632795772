import React, { ReactElement, ReactNode } from 'react'

import './style.css';

interface Props {
    renderHeader(): ReactNode,
    renderDescription?(): ReactNode
}

export default function Card({renderHeader, renderDescription}: Props): ReactElement | null {

    return ( renderHeader() ? 
        <div className='Card'>
            <div className="Card__Header">
                {renderHeader()}
            </div>
            
            {renderDescription &&
            <>
                <div className="divider"></div>
                <div className="Card__Description">
                    {renderDescription()}
                </div>
            </>
            }
           
        </div> : null
    )
}
