import React, { useContext, useEffect } from "react";
import { AppContext, Location } from "context";
import { ShortLink } from "types/ShortLink";
import Http from "services/http";
import LinksList from "Components/LinksList";

import "./style.css";
import LinksListItem from "../LinksListItem";
import { useHistory } from "react-router-dom";



const ArchiveLinksList = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const onDelete = async(link: ShortLink) => {
    try {
      const didDelete = await Http.DeleteShortLink(link.linkId);
      if(didDelete) context.dispatch_archiveLinks({ type: "REMOVE", payload: link.linkId });
    } catch (error) {
      console.error(error);
      if(error.code === 401) history.replace('/login')
    }
    
  };



  const onExpire = async(link: ShortLink) => {
    try {
      const didArchive = await Http.UpdateLinkisNotActive(link.linkId);
      if(didArchive) context.MoveTo(link, Location.archive, Location.expired);
    } catch (error) {
      console.error(error)
      if(error.code === 401) history.replace('/login')
    }
    
  };


  useEffect(()=> {
    const getArchiveLinks = async() =>{
      try {
        const links = await Http.GetLinksArchive();
        context.dispatch_archiveLinks({type:'PUT', payload: links.reverse()})
      } catch (error) {
        if(error.code === 401) history.replace('/login')
      }
    }

    getArchiveLinks();
    // eslint-disable-next-line
  },[])

  return (
    <div className="ArchiveLinksList">
      <LinksList links={context.archiveLinks} render={(link) => 
            <LinksListItem link={link} onDelete={onDelete} onExpire={onExpire}/>
            }/>
    </div>
  );
};




export default ArchiveLinksList;
