import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Datum } from "types/Statistic";

import './style.css';

interface Props {
  height: number;
  width: number;
  title: string;
  data: Datum[];
  fill: string;
}

const BarChartContainer = ({ height, width, title, data, fill }: Props) => {
  return (
    <div className="BarChartContainer">
      <div className="BarChart">
        <div className="BarChart__title">{title}</div>

        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill={fill} />
        </BarChart>
      </div>
    </div>
  );
};

export default BarChartContainer;
