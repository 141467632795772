export const CopyToclipboard = async (text: string): Promise<boolean> => {
  if (navigator.clipboard) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      const elem = document.createElement("textarea");
      document.body.appendChild(elem);
      elem.value = text;
      elem.select();
      elem.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(elem);
    }
  } else {
    const elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    elem.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(elem);
  }
  return true;
};



export const randomColor = () => '#'+Math.random().toString(16).slice(-6)