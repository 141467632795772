import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faKey } from "@fortawesome/free-solid-svg-icons";
import {useHistory} from 'react-router-dom';
import AsynButton from "Components/AsyncButton";
import Card from "Container/Card";

import Http from 'services/http';
import { AppContext } from "context";

const Login = () => {

    const history = useHistory();
    const context = useContext(AppContext);

  const [state_username, setState_username] = useState("");
  const [state_password, setState_password] = useState("");

  const onLogin = async () => {

    if(!state_username || !state_password) return;
    try {
        await Http.Login(state_username, state_password)
        context.set_username(state_username);
        history.replace('/home/dashboard');
    } catch (error) {
        
    }
    

  };

  useEffect(()=> {
    context.set_username('');
  } ,[])

  return (
    <div className="Login">
      <Card
        renderHeader={() => <div className="title">Login</div>}
        renderDescription={() => (
          <>
            <div className="inputContainer">
              <FontAwesomeIcon icon={faUser} />
              <input
                type="text"
                placeholder="Username"
                onChange={(e) => setState_username(e.target.value)}
                value={state_username}
              />
            </div>

            <div className="inputContainer">
              <FontAwesomeIcon icon={faKey} />
              <input
                type="password"
                placeholder="Password"
                onChange={(e) => setState_password(e.target.value)}
                value={state_password}
              />
            </div>

            <AsynButton types="btn-primary" onClick={onLogin}>
              Login
            </AsynButton>

          </>
        )}
      />
    </div>
  );
};

export default Login;
