import React, {useContext, useState, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import {AppContext} from 'context';
import { KeyboardDatePicker } from "@material-ui/pickers";
import {  TextField } from '@material-ui/core';
import Card from "Container/Card";
import DropDownBtn from 'Components/DropDown';
import AsynButton from 'Components/AsyncButton';
import Http from 'services/http';
import classnames from 'classnames';
import './style.css';
import { CopyToclipboard } from "utils";
import { useHistory } from "react-router-dom";
import moment from "moment";


const UrlShorter = () => {
    const context = useContext(AppContext);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [state_isValid, setState_isValid] = useState(true);
    const [state_url, setState_url] = useState('');
    const [state_title, setState_title] = useState('');
    const [state_isButtonCopy, setState_isButtonCopy] = useState(false);
    const [state_copySuccess, setState_copySuccess] = useState(false);
    const [selectedDate, handleDateChange] = useState<Date | null>(null);

    const history = useHistory();

    const onShortClick = async() => {
      try {
        setState_isValid(true)
        if(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm.test(state_url || '')){
          const newLink = await Http.CreateShortLink(state_url, state_title,  selectedDate ? moment(selectedDate.toString()).format('MM/DD/YYYY') : undefined );
          context.dispatch_activeLinks({type: "ADD", payload: newLink});
          setState_url(newLink.shortlink);
          setState_isButtonCopy(true);
          handleDateChange(null); 
          setState_title('')
        }
        else setState_isValid(false);

     
      } catch (error) {
        console.log(error)
        if(error.code === 401) history.replace('/login')
      }
    }


    const Copy = async() => {
      try {
        await CopyToclipboard(state_url);
        setState_copySuccess(true);
      } catch (error) {
        
      }
    }

    const urlChange = (e: React.FormEvent<HTMLInputElement>) =>{
      if(!state_isValid) setState_isValid(true);
      if(state_isButtonCopy) setState_isButtonCopy(false);
      if(state_copySuccess) setState_copySuccess(false);
      setState_url(e.currentTarget.value);
    }

    const classes = classnames('UrlShorter__header', {'invalid': !state_isValid, 'copied': state_copySuccess})
  return (
    <div className="UrlShorter">
      <Card
        renderHeader={() => (
          <div className={classes}>
            <FontAwesomeIcon icon={faLink} />
            <input type="text" placeholder="Paste a long url" onChange={urlChange} value={state_url} ref={inputRef}/>
            {state_isButtonCopy ? 
            <div className='btn btn-action btn-lg' onClick={Copy}>Copy</div>
            :
              <AsynButton types='btn-primary btn-lg' onClick={onShortClick}>
              Shorten
            </AsynButton>}


          </div>
        )}
        renderDescription={() => 
            <div className="UrlShorter__description">
                <DropDownBtn btnText="Advanced" 
                onChange={(isOpen)=> {if(!isOpen) handleDateChange(null); setState_title('')}}
                render={()=>
                  <div className='UrlShorter__options'>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Expire date"
                    format="DD/MM/YYYY"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={date => handleDateChange(date?.toDate() || null)}
                    minDate={Date.now()}
                  />
                  <TextField
                        className="outlined-helperText"
                        label="Title"
                        variant="outlined"
                        onChange={(e)=>setState_title(e.target.value)}
                        value={state_title}
                      />
              </div>
                }/>
            </div>
        }
      />
    </div>
  );
};

export default UrlShorter;
