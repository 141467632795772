import React, { useState, useEffect } from "react";
import Card from "Container/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ShortLink, ShortLinkList } from "types/ShortLink";
import "./style.css";



interface Props{
  links: ShortLinkList,
  render(link: ShortLink): React.ReactNode
}



const LinksList = ({links, render}: Props) => {
  const [state_searchInput, setState_searchInput] = useState("");
  const [state_FilteredLinks, setState_FilteredLinks] = useState<ShortLink[]>([...links].reverse());

  const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setState_searchInput(e.currentTarget.value);
  };


  useEffect(() => {
    const filltered = [...links].reverse().filter(link => link.title.toLowerCase().includes(state_searchInput.toLowerCase()))
    setState_FilteredLinks(filltered);
  }, [state_searchInput, links])


  useEffect(()=>{
    setState_FilteredLinks([...links].reverse());
  },[links])
  
  return (
    <div className="LinksList">
      <Card
        renderHeader={() => (
          <div className="LinksList__header">
            <div className="input_container">
              <FontAwesomeIcon icon={faSearch} />
              <input type="text" placeholder="Search" onChange={onSearch} />
            </div>
          </div>
        )}
        renderDescription={() => (
          <div className="LinksList__description">
            {state_FilteredLinks
            .map((link, index) => <span key={link.linkId} className='LinksList__item'>{render(link)} <div className="divider"></div></span>)}
          </div>
        )}

      />
    </div>
  );
};

export default LinksList;
