import React, { useRef, useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { ShortLink } from "types/ShortLink";
import { CopyToclipboard } from "utils";
import { useHistory } from "react-router-dom";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { AppContext, Location } from "context";

import "./style.css";
import AsyncButton from "Components/AsyncButton";
import TextField from "@material-ui/core/TextField";
import Http from "services/http";
import moment from "moment";
import Time from "Components/Time";
import Popup from "Components/Popup";

interface Props {
  link: ShortLink;
  onArchive?(link: ShortLink): void;
  onExpire?(link: ShortLink): void;
  onDelete?(link: ShortLink): void;
}

const LinksListItem = ({ link, onArchive, onExpire, onDelete }: Props) => {
  const context = useContext(AppContext);

  const inputRef = useRef<HTMLAnchorElement | null>(null);
  const [selectedDate, handleDateChange] = useState<Date | null>(
    link.ExpirationDate ? moment(link.ExpirationDate).toDate() : null
  );
  const [state_title, setState_title] = useState(link.title);
  const [state_url, setState_url] = useState(link.link.origLink);

  const [PopupIsOpen_Details, set_PopupIsOpen_Details] = useState(false);
  const [PopupIsOpen_Edit, set_PopupIsOpen_Edit] = useState(false);
  const [PopupIsOpen_Confirm, set_PopupIsOpen_Confirm] = useState(false);

  const history = useHistory();

  const Copy = async () => {
    const text = inputRef.current?.href || "";

    try {
      await CopyToclipboard(text);
    } catch (error) {}
  };

  const ClearEditing = () => {
    handleDateChange(
      link.ExpirationDate ? moment(link.ExpirationDate).toDate() : null
    );
    setState_title(link.title);
  };



  const gotoDetails = () => {
    history.push(`/home/link/${link.linkId}`);
  };

  const onSave = async () => {
    console.log(state_url, state_title, selectedDate)
    if (!state_title || !state_url) return;
    try {
      const didUpdate = await Http.UpdateLink(
        link.linkId,
        state_title,
        state_url,
        selectedDate
          ? moment(selectedDate.toString()).format("MM/DD/YYYY")
          : undefined
      );
      if (didUpdate) {
        const _link: ShortLink = {
          ...link,
          title: state_title,
          link: { ...link.link, origLink: state_url },
          ExpirationDate: selectedDate
            ? selectedDate.toString()
            : link.ExpirationDate,
        };
        const location = context.getLocation(link.linkId);
        switch (location) {
          case Location.active:
            context.dispatch_activeLinks({ type: "UPDATE", payload: _link });
            break;

          default:
            break;
        }

        ClearEditing();
        set_PopupIsOpen_Edit(false);
      } else throw new Error("Failed");
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const Confirm = () => {
    if (onDelete) onDelete(link);
    set_PopupIsOpen_Confirm(false);
  };


  return (
    <div className="LinksListItem">

    {PopupIsOpen_Details ?
    <Popup 
      isOpen={PopupIsOpen_Details}
      onClose={()=>set_PopupIsOpen_Details(false)}
      title={() => <div className='Popup_details__title'>{link.title}</div>}
      content={() => 
        <div className="Popup_details">
          <div className="Popup_details__url">
            url: <span>{link.link.origLink}</span>
          </div>
          <div className="Popup_details__params_title">
            <div className="title">Parameters</div>
            {link.ExpirationDate && (
              <div className="expire">
                Expire date: <Time format={false} date={link.ExpirationDate} />
              </div>
            )}
          </div>
          <div className="Popup_details__list">
              {link.ReplaceWords && link.ReplaceWords.map(param => 
                <div className={param.isReplace ? 'replace' : ''}>
                  {param.key} = {param.val}
                </div>)}
          </div>

          <div className="btn btn-primary" onClick={()=>set_PopupIsOpen_Details(false)}>
            Close
          </div>
        </div>}
    />: null}



{PopupIsOpen_Edit ?
    <Popup 
      isOpen={PopupIsOpen_Edit}
      onClose={()=>set_PopupIsOpen_Edit(false)}
      title={() => <div>Edit</div>}
      content={() => (
              <div className="LinksListItem__popup_content">
                <TextField
                  className="outlined-helperText"
                  label="Url"
                  variant="outlined"
                  onChange={(e) => setState_url(e.target.value)}
                  value={state_url}
                />
                <TextField
                  className="outlined-helperText"
                  label="Title"
                  variant="outlined"
                  onChange={(e) => setState_title(e.target.value)}
                  value={state_title}
                />
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Expire date"
                  format="DD/MM/YYYY"
                  value={selectedDate}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => handleDateChange(date?.toDate() || null)}
                  minDate={Date.now()}
                />
                <AsyncButton types="btn-primary" onClick={onSave}>
                  Save
                </AsyncButton>
              </div>
            )}
    />: null}




    
{PopupIsOpen_Confirm ?
    <Popup 
      isOpen={PopupIsOpen_Confirm}
      onClose={()=>set_PopupIsOpen_Confirm(false)}
      title={() => <div>Do you wish to continue</div>}
      content={() => 
        <div className="Popup_confirm">
          <div className="btn btn-primary" onClick={Confirm}>
            Confirm
          </div>
          <div className="btn btn-cancel" onClick={()=>set_PopupIsOpen_Confirm(false)}>
            Close
          </div>
        </div>}
    />: null}


      <div className="LinksListItem__options">
        <div className="title_container">
          {link.icon ? (
            <img src={link.icon} alt="favicon" />
          ) : (
            <FontAwesomeIcon icon={faGlobeEurope} />
          )}
          <div className="title">
            <div>
              <a
                href={link.link.origLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
              <Time date={link.dateInsert} />
            </div>

            <div className="shortlink">
              <a
                href={link.shortlink}
                target="_blank"
                rel="noopener noreferrer"
                ref={inputRef}
              >
                {" "}
                {link.shortlink}
              </a>
              <span className="btn btn-sm btn-tag btn-tag-left" onClick={Copy}>
                Copy
              </span>
            </div>
          </div>
        </div>
        <div className="controlls">
          <div className="btn btn-sm" onClick={()=>set_PopupIsOpen_Details(true)}>
            Details
          </div>
          <div className="btn btn-sm" onClick={()=>set_PopupIsOpen_Edit(true)}>
            Edit
          </div>
          {onArchive && (
            <div className="btn btn-sm" onClick={() => onArchive(link)}>
              Archive
            </div>
          )}
          {onExpire && (
            <div className="btn btn-sm" onClick={() => onExpire(link)}>
              Expire
            </div>
          )}

          {onDelete && (
            <div className="btn btn-sm" onClick={()=>set_PopupIsOpen_Confirm(true)}>
              Delete
            </div>
          )}

          {link.ExpirationDate && (
            <div className="expire">
              Expire date: <Time date={link.ExpirationDate} />{" "}
            </div>
          )}
        </div>
      </div>
      <div className="LinksListItem__clicks">
        <div className="clicks">{link.clicks}</div>
        <div className="clicks_btn" onClick={gotoDetails}>
          Clicks
        </div>
      </div>
    </div>
  );
};

export default LinksListItem;
