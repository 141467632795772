import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBriefcase, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const SideMenu = () => {
  return (
    <div className="SideMenu container">
      <NavLink
        activeClassName="btn-primary"
        className="SideMenu_item btn"
        to={{ pathname: "/home/dashboard" }}
      >
        <FontAwesomeIcon icon={faHome} />
        <div className="SideMenu_item_text">Dashboard</div>
      </NavLink>

      <NavLink
        activeClassName="btn-primary"
        className="SideMenu_item btn"
        to={{ pathname: "/home/archive" }}
      >
        <FontAwesomeIcon icon={faBriefcase} />
        <div className="SideMenu_item_text">Archived Links</div>
      </NavLink>

      <NavLink
        activeClassName="btn-primary"
        className="SideMenu_item btn"
        to={{ pathname: "/home/expired" }}
      >
        <FontAwesomeIcon icon={faCalendarAlt} />
        <div className="SideMenu_item_text">Expired Links</div>
      </NavLink>
    </div>
  );
};

export default SideMenu;
