import React, {memo} from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import {Datum} from 'types/Statistic'
import mapData from './world-110m.json';
import './style.css'



interface Props {
    setTooltipContent?: (tooltip: string)=>void,
    data: Datum[]
}


const MapChart = ({ setTooltipContent = (_)=>null, data }: Props) => {

  const max = Math.max(...data.map(item=> typeof item.value === "number" ? item.value as number : 1));
  const colorScale = scaleLinear()
  .domain([0, max])
  .range([parseInt('ffedea',16), parseInt('ff5233',16)]);


    return (
    
      <ComposableMap
      data-tip=""
      className='Map'
      width={1000}
      height ={480}
    >
      <Sphere stroke="#E4E5E6" strokeWidth={0.5} fill='#E4E5E6' id='rsm-sphere'/>
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      {data.length > 0 && (
        <Geographies geography={mapData}>
          {({ geographies }) =>
            geographies.map(geo => {
              const d = data.find(s => s.name === geo.properties.NAME_LONG);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={ d ? typeof d.value === "number" ? `#${Math.floor(colorScale(d.value as number)).toString(16)}` : '#004c8c' : "#F5F4F6"}
                  onMouseEnter={() => {
                    const { NAME } = geo.properties;
                    setTooltipContent(`${NAME} — ${typeof d?.value === "number" ? Math.round(d.value ) : d?.value || 0}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  
    );
  };
  
  export default memo(MapChart);
  