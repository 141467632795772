import React, {useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'context';

const Header = () => {

    const history = useHistory();
    const context = useContext(AppContext);

    const Logout = () => {
        sessionStorage.removeItem('token');
        history.replace('/login');
    }


    return (
        <div className='Header container'>
            <div className="Header__title">MemoGlobal Short Url</div>
            <div className="Header__controlls">
                <div className="controll_item btn btn-primary" id="logout" onClick={Logout}>
                    <div className="controll_item_text">
                        {context.username ? context.username : 'Logout'}
                    </div>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
            </div>
        </div>
    )
}

export default Header
