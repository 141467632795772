import React, {useState, useEffect} from 'react'
import Card from 'Container/Card';

import './style.css'
import { useHistory } from 'react-router-dom';

interface Props<T>{
    maximumItems: number,
    renderItem(data: T): React.ReactNode,
    title: string,
    fetchFeed: () => Promise<T[]>,
    onChange?: (newData: T[]) => void

}

const timeout = parseInt(process.env.REACT_APP_FEED_TIMEOUT || '5000') ;

const RealTimeFeed = <T extends object>({maximumItems, renderItem, title, fetchFeed, onChange = (_: T[]) => null }: Props<T>) => {

    const [items , setState_items] = useState<T[]>([]);
    const history = useHistory();

    useEffect(() => {

        let timeOutId = 0;
        const fetchData = async() => {
            try {
                const data = await fetchFeed();
                setState_items(data);
                onChange(data);
            } catch (error) {
                if(error.code === 401) {
                    clearTimeout(timeOutId)
                    history.replace('/login');
                    return;
                }
            }

            timeOutId = window.setTimeout(fetchData, timeout)
        }
        fetchData();

        
        return () => clearTimeout(timeOutId);
        
    // eslint-disable-next-line
    }, [])


    return (
        <div className='RealTimeFeed'>
            <Card 
            renderHeader={()=><div className='RealTimeFeed__title'>{title}</div>}
                renderDescription = {() => 
                    items.map((item, index) =><div key={index} className='RealTimeFeed__item'>{renderItem(item)}<div className='divider'></div></div>)
                }
            />
        </div>
    )
}

export default RealTimeFeed
